import React from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"

const LyneUpHomeBannerWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  position: relative;
  background: #79c6d4;
  color: #fff;
  height: 400px;

  @media (max-width: 1080px) {
    height: 350px;
  }

  @media (max-width: 980px) {
    height: 320px;
  }

  @media (max-width: 550px) {
    background: #f0f0f0;
    height: auto;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  max-width: 1200px;
  width: 100%;
  height: 100%;

  @media (max-width: 850px) {
    padding: 0px 20px 0px 0px;
  }

  @media (max-width: 550px) {
    background: #79c6d4;
    flex-direction: column-reverse;
    justify-content: flex-start;
    display: flex;
    position: relative;
    border: 0px;
    padding: 0px 20px;
    max-width: 100%;
    width: 100%;
    border-bottom-right-radius: 60px;
  }
`

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 50%;
  flex: 1;
  padding: 0px 20px 0px 0px;
  justify-content: flex-end;
  position: relative;

  @media (max-width: 850px) {
    padding: 0px 10px 0px 0px;
  }

  @media (max-width: 550px) {
    max-width: 100%;
    padding: 0px;
  }
`

const Image = styled.img`
  width: 100%;
  object-fit: contain;
  margin: 0px;

  @media (max-width: 850px) {
    object-fit: cover;
    position: absolute;
    height: 100%;
    object-position: right center;
  }

  @media (max-width: 850px) {
    object-fit: cover;
    position: relative;
    height: 100%;
    object-position: right center;
  }
`

const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 50%;
  flex: 1;
  padding: 0px 0px 0px 20px;

  @media (max-width: 850px) {
    padding: 0px 0px 0px 10px;
  }

  @media (max-width: 550px) {
    max-width: 100%;
    padding: 40px 0px 0px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 50%;
  flex: 1;
  max-width: 430px;
  padding: 0px;
  justify-content: center;

  @media (max-width: 1080px) {
    max-width: 100%;
  }

  @media (max-width: 550px) {
    max-width: 100%;
  }
`

const Title = styled.h1`
  color: #262626;
  font-size: ${({ lang }) => (lang === "de" ? "34px" : "21px")};
  margin: 0px;
  line-height: 1;
  font-family: "Museo";
  font-weight: 700;
  margin-bottom: 15px;
  position: relative;
  display: flex;
  max-width: 100%;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 850px) {
    font-size: 18px;
  }

  @media (max-width: 550px) {
    width: 100%;
    align-items: center;
    font-size: 18px;
  }
`

const Separator = styled.div`
  height: 4px;
  background: #fade14;
  background: #fa7f3f;
  width: 100%;
  max-width: 150px;
  margin-bottom: 15px;

  @media (max-width: 550px) {
    margin-top: 5px;
    max-width: 260px;
  }
`

const BottomText = styled.div`
  font-family: "Museo";
  font-weight: ${({ lang }) => (lang === "en" ? "500" : "700")};
  font-size: 18px;
  line-height: ${({ lang }) => (lang === "en" ? "1.3" : "1")};
  margin-bottom: 15px;
  color: #262626;
  text-transform: ${({ lang }) => (lang === "en" ? "inherit" : "uppercase")};
  width: 100%;

  p {
    margin: 0px;
  }

  ul {
    list-style: none;
    margin: 0px;
  }

  li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  span {
    color: #79c6d4;
    background: #262626;
    border-radius: 50%;
    margin-right: 8px;
    max-width: 26px;
    width: 100%;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 850px) {
    font-size: 16px;
  }

  @media (max-width: 550px) {
    font-size: 18px;
    margin-bottom: 5px;
  }

  @media (max-width: 360px) {
    font-size: 16px;
  }
`

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;

  @media (max-width: 550px) {
    justify-content: flex-start;
  }
`

const LogoImage = styled.img`
  width: 100%;
  margin: 0px;
  max-width: 170px;
  margin-right: 20px;
  object-fit: contain;

  @media (max-width: 550px) {
    margin-right: 15px;
    max-width: 150px;
  }

  @media (max-width: 400px) {
    max-width: 150px;
  }
`

const TopText = styled.div`
  font-family: "Museo";
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  color: #262626;
  padding: 10px 15px;
  background: #fff;
  border-radius: 15px 0px 15px 0px;

  @media (max-width: 360px) {
    font-size: 16px;
    padding: 8px 12px;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;

  @media (max-width: 850px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: 550px) {
    width: 100%;
    display: none;
  }
`

const MobileButtonWrapper = styled.div`
  display: none;
  position: relative;
  z-index: 1;

  @media (max-width: 550px) {
    display: flex;
    width: 100%;
    padding: 20px;
  }
`

const Button = styled(Link)`
  background: ${props => props.theme.colors.button.black};
  color: #fff;
  border-radius: 5px;
  font-size: 18px;
  line-height: 1;
  max-width: 100%;
  font-family: "Museo";
  font-weight: 700;
  text-align: center;
  padding: 12px 20px;
  border-radius: 25px 0px;
  position: relative;

  :hover {
    color: #262626;
    background: ${props => props.theme.gradient.buttonGradient1};
    :after {
      background: ${props => props.theme.colors.button.black};
    }
  }

  :after {
    content: "";
    position: absolute;
    top: 5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    background: ${props => props.theme.gradient.buttonGradient1};
    border-radius: 25px 0;
    z-index: -1;
  }

  @media (max-width: 550px) {
    width: 100%;
    font-size: 16px;
  }
`

const ProductLink = styled(Link)`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
`

const ProductLinkTitle = styled.span`
  display: none;
`

export const LyneUpHomeBanner = ({ intl, data }) => (
  <LyneUpHomeBannerWrapper>
    <ContentWrapper>
      <LeftWrapper>
        <Image
          className="lazyload"
          data-src="https://static.percko.com/uploads/5ce4b6c5-447e-4010-a8cf-94f9e815d389.png"
          alt={intl.formatMessage({ id: "percko_couple_lyne_up_soutient_dos" })}
        />
      </LeftWrapper>
      <RightWrapper>
        <TextWrapper>
          {/* <Separator /> */}
          {data.lyneup_banner_text.logo_image && (
            <LogoWrapper>
              {data.lyneup_banner_text.logo_image && (
                <LogoImage
                  className="lazyload"
                  data-src={data.lyneup_banner_text.logo_image}
                />
              )}
              {data.lyneup_banner_text.top_text && (
                <TopText
                  dangerouslySetInnerHTML={{
                    __html: data.lyneup_banner_text.top_text,
                  }}
                />
              )}
            </LogoWrapper>
          )}
          {data.lyneup_banner_text.title && (
            <Title
              dangerouslySetInnerHTML={{
                __html: data.lyneup_banner_text.title,
              }}
            />
          )}
          {data.lyneup_banner_text.text && (
            <BottomText
              dangerouslySetInnerHTML={{
                __html: data.lyneup_banner_text.text,
              }}
              lang={intl.locale}
            />
          )}
          <ButtonWrapper>
            {data.lyneup_banner_text.button_text &&
              data.lyneup_banner_text.button_url && (
                <Button to={data.lyneup_banner_text.button_url}>
                  {data.lyneup_banner_text.button_text}
                </Button>
              )}
          </ButtonWrapper>
        </TextWrapper>
      </RightWrapper>
    </ContentWrapper>
    {data.lyneup_banner_text.button_text && data.lyneup_banner_text.button_url && (
      <MobileButtonWrapper>
        <Button to={data.lyneup_banner_text.button_url}>
          {data.lyneup_banner_text.button_text}
        </Button>
      </MobileButtonWrapper>
    )}
    {data.lyneup_banner_text.button_url && (
      <ProductLink to={data.lyneup_banner_text.button_url}>
        <ProductLinkTitle></ProductLinkTitle>
      </ProductLink>
    )}
  </LyneUpHomeBannerWrapper>
)

export default injectIntl(LyneUpHomeBanner)
